<template>
  <div class="head">
    <!-- 头部图片 -->
    <div class="head-top"></div>
    <!-- 上 -->
    <div class="middle">
      <div class="middle-top">
        <div class="caput">
          <div class="img-1"></div>
          <div class="caput-size">公安合成作战指挥平台</div>
          <div class="img-2"></div>
        </div>
        <div class="fundus">
          整合刑侦、网安、信息研判、图像侦查、技术侦查等部门精干警力组建合成作战指挥中心，利用以刑侦为先导，网侦图侦为依托，数据统筹研判、信息系统汇总为支撑的大数据综合信息平台，简化流程、深度融合、精确分析、智能提炼，
          为一线民警案件侦破提供快速便捷的服务。
        </div>
      </div>
      <div class="middle-text">合作案例</div>
      <div class="img-flx">
        <div class="middle-flex">
          <img src="../../assets/security/bureau.png" alt="" />
          <div class="flx-size">赣州市公安局开发区分局</div>
        </div>
        <div class="middle-flex">
          <img src="../../assets/security/substation.png" alt="" />
          <div class="flx-size">楚雄市公安局</div>
        </div>
      </div>
    </div>
    <!-- 中 -->
    <div class="medium">
      <div class="medium-one"></div>
      <div class="medium-two">
        <div class="caput">
          <div class="img-1"></div>
          <div class="caput-size">平台四大优势</div>
          <div class="img-2"></div>
        </div>
        <div class="medium-middle">
          <div v-for="(item, index) in active" :key="index" class="middle-item">
            <img src="../../assets/platform/counter.png" alt="" />
            <div class="middle-size">
              {{ item.name }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      active: [
        {
          name: "系统成熟、稳定可靠",
        },
        {
          name: "与公安多个主干数据库对接，实现数据碰撞比对与分析",
        },
        {
          name: "理念先进，集人员线索研判、案件线索研判、视频侦查研判、调控人员研判于一体",
        },
        {
          name: "流程自定义、表单自定义、权限自定义等",
        },
      ],
    };
  },
};
</script>
<style lang="less" scoped>
.head {
  .head-top {
    height: 1079px;
    background: url("../../assets/platform/background.png") no-repeat;
    background-size: 100% 100%;
  }
  .middle {
    background: #fff;
    padding: 93px 165px 81px 165px;
  }
  .middle-top {
    width: 1599px;
    height: 273px;
    background: url("../../assets/security/twig.png") no-repeat;
    background-size: 100% 100%;
  }
  .caput {
    display: flex;
    justify-content: center;
    padding-top: 50px;
    padding-bottom: 41px;
    .img-1 {
      width: 38px;
      height: 36px;
      margin-right: 31px;
      background: url("../../assets/security/right-round.png") no-repeat;
      background-size: 100% 100%;
    }
    .caput-size {
      font-size: 32px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #ffffff;
      line-height: 30px;
    }
    .img-2 {
      width: 38px;
      height: 36px;
      background: url("../../assets/security/left-round.png") no-repeat;
      background-size: 100% 100%;
      margin-left: 31px;
    }
  }
  .fundus {
    text-align: initial;
    margin-left: 59px;
    text-indent: 1em;
    // margin: 0 53px 0 59px;
    width: 1458px;
    font-size: 24px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 30px;
  }
  .img-flx {
    display: flex;
    padding-top: 40px;
  }
  .middle-flex {
    position: relative;
  }
  .middle-flex:first-child {
    margin-right: 60px;
  }
  .flx-size {
    position: absolute;
    bottom: 38px;
    left: 58px;
    z-index: 99;
    font-size: 32px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #ffffff;
    line-height: 30px;
  }
}
.medium {
  padding: 154px 160px 209px 160px;
  padding-left: 161px;
  background: url("../../assets/security/back.png") no-repeat;
  background-size: 100% 100%;
  .medium-one {
    width: 1414px;
    height: 257px;
    background: url("../../assets/platform/bureau.png") no-repeat;
    background-size: 100% 100%;
    margin-left: 97px;
  }
  .medium-two {
    .caput {
      justify-content: inherit;
      margin-left: 612px;
      margin-top: 140px;
      margin-bottom: 50px;
    }
    .img-1 {
      background: url("../../assets/platform/left.png") no-repeat;
      background-size: 100% 100%;
    }
    .img-2 {
      background: url("../../assets/platform/right.png") no-repeat;
      background-size: 100% 100%;
    }
    .caput-size {
      font-size: 40px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #333333;
      line-height: 30px;
    }
  }
  .medium-middle {
    padding: 20px 0 55px 0;
    width: 1600px;
    background: url("../../assets/platform/middle.png") no-repeat;
    background-size: 100% 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .middle-item {
    display: flex;
    margin-top: 35px;
    text-align: left;
    img {
      width: 50px;
      height: 37px;
      margin-right: 31px;
    }
    .middle-size {
      width: 655px;
      font-size: 24px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #333333;
    }
  }
  .middle-item:first-child{
    margin-left: 50px;
  }
  .middle-item:nth-child(3) {
    margin-left: 50px;
    img{
      margin-top: 10px;
    }
  }
}

.middle-text {
  text-align: initial;
  margin-top: 91px;
  font-size: 32px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #222222;
  line-height: 30px;
}
</style>
