/*
 * @Author: flf flf@youlove.com.cn
 * @Date: 2022-12-27 09:47:23
 * @LastEditors: flf flf@youlove.com.cn
 * @LastEditTime: 2022-12-28 09:27:03
 * @FilePath: \ulove-web\src\main.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import scroll from 'vue-seamless-scroll'
Vue.use(scroll)

Vue.config.productionTip = false
Vue.use(router)

import BaiduMap from 'vue-baidu-map'
 
Vue.use( BaiduMap, { ak : 'F8zCcbYqclQyl1V6vjUltCcYzqj9m0PW' } )
import animated from 'animate.css'
Vue.use(animated)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
