var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ladder"},[_c('div',{staticClass:"banner banner1"}),_vm._m(0),_c('div',{staticClass:"banner banner3"},[_c('div',{staticClass:"banner3Content"},_vm._l((_vm.banner3List),function(item,index){return _c('div',{key:index,staticClass:"banner3ContentItem",style:({ backgroundImage: 'url(' + item.url + ')' }),on:{"mouseenter":function($event){return _vm.maskShow(index)},"mouseleave":_vm.maskMove}},[(_vm.active == index)?_c('div',{staticClass:"banner3Mask"}):_vm._e(),_c('div',{staticClass:"banner3ItemTitle"},[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"banner3ItemContent"},[_vm._v(_vm._s(item.content))])])}),0)]),_vm._m(1),_c('div',{staticClass:"banner banner5"},[_c('div',{staticClass:"banner5Center"},_vm._l((_vm.banner5List),function(item,index){return _c('div',{key:index,staticClass:"banner5Item"},[_c('img',{attrs:{"src":item.url,"alt":""}}),_c('div',{staticClass:"banner5Text"},[_vm._v(_vm._s(item.text))])])}),0)]),_c('div',{staticClass:"banner banner6"},[_c('div',{staticClass:"banner6Center"},_vm._l((_vm.banner6List),function(item,index){return _c('div',{key:index,staticClass:"banner6Item",style:({
  backgroundImage: 'url(' + item.url + ')',
  marginRight: index % 3 == 2 ? '0' : '30px',
  marginTop: index > 2 ? '30px' : '0',
})},[_c('div',{staticClass:"banner6ItemFooter"},[_c('div',{staticClass:"Num"},[_vm._v(_vm._s("0" + (index + 1)))]),_c('div',{staticClass:"Text"},[_vm._v(_vm._s(item.text))])])])}),0)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"banner banner2"},[_c('div',{staticClass:"ladderBanner2Content"},[_c('div',{staticClass:"ladderBanner2center"},[_c('div',{staticClass:"banner2Title"},[_vm._v("好用点点电梯控制系统")]),_c('div',{staticClass:"banner2Content"},[_vm._v("          在疫情防控常态化背景下，针对电梯按键交叉感染场景，好用点点智能电梯控制系统支持刷卡、扫二维码识别楼层乘坐电梯，实现无接触智能乘梯有 效减少感染风险同时支持黑白名单管理，有效限制无权乘梯人员、闲杂人员，降低电梯使用频率，节省电梯能耗；支持VIP乘梯模式，通过动态人脸识别、 远距离感应无感智能识别技术，快速识别VIP用户身份为其预约乘梯，无需VIP用户等待，满足住户安全和物业服务多场景化需求。 ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"banner banner2 banner4"},[_c('div',{staticClass:"ladderBanner2Content ladderBanner4Content"},[_c('div',{staticClass:"ladderBanner2center"},[_c('div',{staticClass:"banner2Title"},[_vm._v("好用点点电梯阻车系统")]),_c('div',{staticClass:"banner2Content"},[_vm._v("          近年来，电动车违规停放、楼道充电导致的火灾屡屡发生，好用点点智能电梯阻车系统基于AI深度学习及海量人车数据校验算法，能够进行精准人车识别，实时阻拦电动车上梯。既保障了电梯和乘梯人的安全，更提高了物业监管水平和效率，从源头上杜绝电动车进楼入户导致的火灾发生。 ")])])])])
}]

export { render, staticRenderFns }