<template>
  <div class="home">
    <topnavigation />
    <div class="swiper">
      <div class="swiper-container">
        <div class="swiper-wrapper" style="width: 820px">
          <div class="swiper-slide" @click="goUrl('/hyy')">
            <img src="../assets/hyy/banner.png" alt="" />
            <div class="probation" @click="probation">
              好用云免费试用一年 | 立即试用
            </div>
          </div>
          <div class="swiper-slide" @click="goUrl('/Yj/Charge')">
            <img src="../assets/yingjian/chargeBanner1.png" alt="" />
          </div>
          <div class="swiper-slide" @click="goUrl('/Yj/Access')">
            <img src="../assets/yingjian/accessBg.png" alt="" />
          </div>
        </div>
        <!-- 如果需要分页器 -->
        <div class="swiper-pagination"></div>

        <!-- 如果需要导航按钮 -->
        <!-- <div class="swiper-button-prev"></div>
        <div class="swiper-button-next"></div> -->

        <!-- 如果需要滚动条 -->
        <!-- <div class="swiper-scrollbar"></div> -->
      </div>
    </div>
    <div class="first">
      <p class="title">数据改变生活，助力数字化升级</p>
      <p class="text">
        杭州尤拉夫科技有限公司深耕智慧城市数十年，现已服务17000+合作伙伴
      </p>
      <div class="img-box">
        <div class="item" :class="imgactive == index ? 'item-active' : 'item'" v-for="(item, index) in imglist"
          :key="index" @mouseenter="mouseEnter(index, $event)">
          <img v-if="item.url" :src="`${item.url}`" alt="" />
          <p class="item-text">
            <span style="font-size: 24px; margin-right: 10px">{{
              item.text
            }}</span>
            <span style="font-size: 40px; font-family: Helvetica; font-weight: bold">{{ item.num }}</span>
          </p>
        </div>
      </div>
    </div>
    <div class="second">
      <p class="title">核心技术</p>
      <p class="text">
        以视觉+语音等多模态感知为基础，建立视觉认知，语言认知，
      </p>
      <p class="text">
        环境认知等多模态认知融合，打造智能决策系统，实现人工智能技术闭环
      </p>
      <div class="list">
        <div class="list-item f74a6">
          <div class="img-box">
            <img src="../assets/commun.png" alt="" />
          </div>
          <p class="item-title">全网化融合通信</p>
          <p class="item-text">
            局域网，内网等数据通信双向互通，使<br />
            数据流动起来
          </p>
        </div>
        <div class="list-item fff">
          <div class="img-box">
            <img style="margin-top: 76px" src="../assets/manydata.png" alt="" />
          </div>

          <p class="item-title">数据全域治理</p>
          <p class="item-text">
            数据多渠道聚合采集，清洗，治理，分析<br />
            使数据规范标准
          </p>
        </div>
        <div class="list-item c92">
          <div class="img-box">
            <img src="../assets/machine.png" alt="" />
          </div>
          <p class="item-title">机器视觉处理</p>
          <p class="item-text">
            图像识别，视频结构化，使机器更智能<br />
            数据更准确
          </p>
        </div>
        <div class="list-item fff">
          <div class="img-box">
            <img src="../assets/ai.png" alt="" />
          </div>
          <p class="item-title">AI人工智能</p>
          <p class="item-text">
            语义理解、 知识图谱、 机器学习等智能<br />认知自主学习技术，使数据产生真正的
            <br />
            价值
          </p>
        </div>
      </div>
    </div>
    <div class="third">
      <p class="title">业务领域</p>
      <p class="text">以客户为中心，从客户的需求点出发</p>
      <div class="list">
        <div class="list-item" v-for="(item, index) in ywlylist" :key="index"
          :class="index == ywlyIndex ? 'list-item-active' : ''" @click="switchover(item, index)">
          <p>{{ item.text }}</p>
        </div>
      </div>
      <p class="word">
        {{ name }}<br />
        {{ tile }}<br />
        {{ tileText }}
      </p>
      <img class="ywly-img" :src="imgUrl" alt="" />
    </div>
    <div class="fourth">
      <p class="title">尤拉夫科技简介</p>
      <div class="content">
        <div class="content-top">
          <div><img class="yinghao" src="../assets/yinghao.png" alt="" /></div>
          <div class="word">
            <p style="text-indent: 2em">
              杭州尤拉夫科技有限公司是一家11年深耕于政府信息化建设的国家高新技术企业，现拥有70余项软件著作权。公司背靠浙江大学精深的科研实力，组建了以“国千”带队的科研团队，拥有机械、水利、化学、材料、计算机等5大学科的科研力量，为公安、应急、消防、综治等政府机构
              提供先进数智化产品、高质量高效率服务及完整解决方案。
            </p>
            <p style="text-indent: 2em">
              近年来，公司围绕智能物联网、互联网+等关键方向持续创新，将“万物互联”覆盖社区、园区、工业等诸多场景中，逐渐成为智慧城市建设细分领域的新锐企业。
            </p>
          </div>
        </div>

        <div class="numeric-list">
          <div v-for="(item, index) in numericlist" :key="index" class="numeric-item" :class="index != 2 ? 'border' : ''">
            <span class="num">{{ item.num }}</span>
            <span class="dw">{{ item.dw }}</span>
            <p class="text">{{ item.text }}</p>
          </div>
        </div>
      </div>
    </div>
    <bottombanner />
    <div v-if="visble" class="overspread">
      <div class="pop-up">
        <div class="lateron" @click="probation">稍后试用</div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import Bottombanner from "../components/bottombanner.vue";
import Topnavigation from "../components/topnavigation.vue";
import "swiper/dist/js/swiper";
import "swiper/dist/css/swiper.css";
import Swiper from "swiper";
export default {
  name: "Home",
  components: {
    //HelloWorld
    Bottombanner,
    Topnavigation,
  },
  data() {
    return {
      ywlyIndex: 0,
      visble: false,
      imgUrl: require("../assets/ywly.png"),
      name: "应急管理平台设计建设，本着“平战结合”的思想，重点提升日常应急管理和突发事件应急处置中的信息化能力，",
      tile: "系统涵盖监测监控，预测预警，应急值守，险患防控，资源保障，辅助决策，应急指挥，",
      tileText:
        "数据分析和合作协同等业务领域，可以帮助政府客户快速构建省市、区县、街镇一体化的多级应急联动平台体系。",

      ywlylist: [
        {
          text: "应急管理",
          name: "应急管理平台设计建设，本着“平战结合”的思想，重点提升日常应急管理和突发事件应急处置中的信息化能力，",
          tile: "系统涵盖监测监控，预测预警，应急值守，险患防控，资源保障，辅助决策，应急指挥，",
          tileText:
            "数据分析和合作协同等业务领域，可以帮助政府客户快速构建省市、区县、街镇一体化的多级应急联动平台体系。",
          imgUrl: require("../assets/ywly.png"),
        },
        {
          text: "公安信息化",
          name: "整合刑侦、网安、信息研判、图像侦查、技术侦查等部门精干警力组建合成作战指挥中心，",
          tile: "利用以刑侦为先导，网侦图侦为依托，数据 统筹研判、信息系统 汇总为支撑的大数据综合信息平台，",
          tileText:
            "简化流程、深度融合、精确分析、智能提炼， 为一线民警案件侦破提供快速便捷 的服务。",
          imgUrl: require("../assets/security.png"),
        },
        {
          text: "智慧社区",
          name: "好用点点智慧社区解决方案，面向各类型社区提供与公安内网打通的SAAS平台、智慧硬件产品，",
          tile: "从治安防控升级、社区管理高效、居住 体验优化三个维度出发，以一标三实信息采集为基础，融合智慧门禁、智慧道闸、 智慧充电桩、智慧梯控等系统，",
          tileText:
            "搭建数字看板、数字驾驶舱，自动分析辖区风险并提供精准的人口管理、流动人员管理、疫情管控等创新应用，政府、职能单位与小区物业可联动共治，全面提高社区智治水平。",
          imgUrl: require("../assets/community.png"),
        },
        {
          text: "智慧园区",
          name: "智慧园区解决方案，为办公楼宇、企业园区提供定制化智慧管理系统，软硬件深度融合，",
          tile: "运用物联网、人工智能、大数据分析等技术，满 足智慧通行、智慧安防、智慧停车、智慧迎宾等多样化场景需求，",
          tileText:
            "实现对外服务形象提升；可视化数字驾驶舱让管理端一屏触达用户信息， 分析人员风险，疫情精准防控，实现运营降本增效，从而构建新一代安全、高效、绿色的智慧园区。",
          imgUrl: require("../assets/garden.png"),
        },
      ],
      numericlist: [
        {
          num: "10",
          text: "深耕于政府信息化建设",
          dw: "年+",
        },
        {
          num: "70",
          text: "已取得的软件著作权",
          dw: "项+",
        },
        {
          num: "5",
          text: "背靠浙大精深的科研实力",
          dw: "科研力量",
        },
      ],
      imglist: [
        {
          url: `img/item1.png`,
          text: "覆盖省级市地区",
          num: "20+",
        },
        {
          url: "img/item2.png",
          text: "服务企事业单位",
          num: "500+",
        },
        {
          url: `img/item3.png`,
          text: "服务住宅小区",
          num: "800+",
        },
        {
          url: `img/item4.png`,
          text: "服务企业园区",
          num: "1000+",
        },
      ],
      imgactive: 0,
    };
  },
  created() {
    console.log('导航跳转产生路由参数',this.$route)
  },
  mounted() {
    var mySwiper = new Swiper(".swiper-container", {
      direction: "horizontal", // 垂直切换选项
      //mousewheel: true, //滚轮
      autoplay: {
        //自动开始
        delay: 500, //时间间隔
        disableOnInteraction: false, //*手动操作轮播图后不会暂停*
      },
      loop: true, // 循环模式选项

      // 如果需要分页器
      pagination: {
        el: ".swiper-pagination",
        clickable: true, // 分页器可以点击
      },

      // 如果需要前进后退按钮
      // navigation: {
      //   nextEl: '.swiper-button-next',
      //   prevEl: '.swiper-button-prev',
      // },

      // 如果需要滚动条
      scrollbar: {
        el: ".swiper-scrollbar",
      },
    });

    mySwiper.el.onmouseover = function () {
      //鼠标放上暂停轮播
      mySwiper.autoplay.stop();
    };
    mySwiper.el.onmouseleave = function () {
      mySwiper.autoplay.start();
    };
  },
  methods: {
    probation() {
      this.visble = !this.visble;
    },
    switchover(item, i) {
      this.name = item.name;
      this.tile = item.tile;
      this.tileText = item.tileText;
      this.imgUrl = item.imgUrl;
      this.ywlyIndex = i
    },
    goUrl(val) {
      let routeData = this.$router.resolve({
        path: val,
        // query: {goodsId:'1111'}
      });
      window.open(routeData.href, '_blank');
    },

    //鼠标移入
    mouseEnter(item, e) {
      console.log("鼠标移入首页图片", item, e);
      this.imgactive = item;
    },
  },
};
</script>
<style lang="less" scoped>
.home {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
  width: 100% !important;
  display: flex;
  flex-direction: column;
  align-items: center;

  /* margin-top: 60px; */
  .swiper {
    // width:1920px;
    width: 100%;
    height: 1080px;

    .swiper-container {
      // width: 1920px;
      width: 100%;
      height: 1080px;
      // margin-top: 120px;
      // background-color: red;
    }

    .swiper-slide {
      // width: 1920px !important;
      width: 100%;
      height: 1080px;
      border-radius: 0px;
      background-color: #fff;
      margin-right: 0px;
      position: relative;

      img {
        // width: 1920px !important;
        width: 100%;
        // height: 1080px;
        object-fit: contain;
      }

      .probation {
        position: absolute;
        bottom: 372px;
        left: 160px;
        width: 486px;
        height: 66px;
        background: #0063be;
        border-radius: 33px;
        font-size: 26px;
        font-family: PingFang SC;
        font-weight: 800;
        color: #ffffff;
        line-height: 66px;
        cursor: pointer;
      }
    }

    .swiper-pagination {
      width: 40%;
      margin-left: 60%;
      padding-bottom: 50px;
    }

    /deep/.swiper-pagination-bullet {
      width: 80px !important;
      height: 6px !important;
      border-radius: 0 !important;
    }

    /deep/.swiper-pagination-bullet-active {
      color: #0063be !important;
    }
  }

  .first {
    // width:1920px;
    width: 1920px;
    height: 1080px;
    overflow: hidden;
    background: #ffffff;

    p {
      margin: 0;
      text-align: left;
      margin-left: 160px;
      line-height: 1;
    }

    .title {
      font-size: 40px;
      font-family: PingFang SC;
      font-weight: 800;
      color: #222222;
      margin-top: 167px;
      margin-bottom: 28px;
    }

    .text {
      font-size: 22px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #333333;
    }

    .img-box {
      display: flex;
      width: 1596px;
      height: 552px;
      // background: #0074f4;
      margin: 125px auto 0;
      font-family: PingFang SC;

      .item {
        width: 339px;
        height: 552px;
        position: relative;

        .item-text {
          margin: 0;
          position: absolute;
          bottom: 35px;
          left: 25px;
          color: #fff;
        }
      }

      .item img {
        z-index: -1;
        width: 100%;
        height: 100%;
      }

      .item-active {
        // width: 579px;
        height: 552px;
        position: relative;
        animation: widthBig 500ms ease forwards;

        .item-text {
          margin: 0;
          position: absolute;
          bottom: 35px;
          left: 25px;
          color: #fff;
        }
      }
    }
  }

  @keyframes widthBig {
    0% {
      width: 339px
    }

    100% {
      width: 579px;
    }
  }

  .second {
    // width:1920px;
    width: 1920px;
    height: 1080px;
    overflow: hidden;
    background: url("../assets/home-second.png") no-repeat 100% 100%;

    p {
      margin: 0;
      text-align: left;
      margin-left: 160px;
      line-height: 1;
    }

    .title {
      font-size: 40px;
      font-family: PingFang SC;
      font-weight: 800;
      color: #222222;
      margin-top: 167px;
      margin-bottom: 31px;
    }

    .text {
      font-size: 22px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #333333;
      margin-bottom: 15px;
    }

    .list {
      margin-top: 95px;
      margin-left: 160px;
      width: 1600px;
      height: 432px;
      display: flex;
    }

    .list .list-item {
      width: 400px;
      height: 432px;

      .img-box {
        height: 240px;
      }

      img {
        margin-top: 61px;
      }

      p {
        margin: 0;
        text-align: center;
      }

      .item-title {
        font-size: 32px;
        font-family: PingFang SC;
        font-weight: bold;
        margin-bottom: 30px;
      }

      .item-text {
        line-height: 26px;
      }
    }

    .f74a6 {
      background: #4f74a6;
      color: #ffffff;
    }

    .fff {
      background: #ffffff;
      color: #333333;
    }

    .c92 {
      background: #566c92;
      color: #ffffff;
    }
  }

  .third {
    // width:1920px;
    width: 1920px;
    height: 1080px;
    background: #fff;
    overflow: hidden;

    p {
      margin: 0;
    }

    .title {
      font-size: 40px;
      font-family: PingFang SC;
      font-weight: 800;
      color: #333333;
      text-align: right;
      margin-right: 170px;
      margin-top: 81px;
      margin-bottom: 30px;
      line-height: 1;
    }

    .text {
      font-size: 22px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #333333;
      text-align: right;
      line-height: 1;
      margin-right: 170px;
    }

    .list {
      display: flex;
      margin: 80px auto 0;
      height: 164px;
      // width: 1453px;
      width: 75%;
      justify-content: space-between;

      .list-item {
        // width: 285px;
        width: 19%;
        height: 164px;
        border: 1px solid #adadad;
        font-size: 32px;
        font-family: PingFang SC;
        font-weight: 800;
        color: #333333;
        line-height: 164px;
        cursor: pointer;
      }

      .list-item:hover {
        width: 19%;
        height: 164px;
        border: 1px solid #adadad;
        font-size: 32px;
        font-family: PingFang SC;
        font-weight: 800;
        line-height: 164px;
        background: #0074f4;
        color: #ffffff;
      }

      .list-item-active {
        background: #0074F4;
        color: #FFFFFF;
      }
    }

    .word {
      // width: 889px;
      // height: 86px;
      font-size: 18px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 34px;
      text-align: left;
      margin-left: 12.5%;
      margin-top: 82px;
    }

    .ywly-img {
      margin: 97px auto 0;
    }
  }

  .fourth {
    // width:1920px;
    width: 1920px;
    height: 1080px;
    overflow: hidden;
    background: url("../assets/jianshao.png") no-repeat 100% 100%;

    p {
      margin: 0;
    }

    .title {
      font-size: 40px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #333333;
      margin-top: 119px;
    }

    .content {
      margin: 142px auto;
      width: 1228px;
      height: 400px;

      .content-top {
        display: flex;
      }

      .content-top p {
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 38px;
        text-align: left;
      }

      .content-top .word {
        width: 1157px;
        margin: 26px 0 0 14px;
      }

      .numeric-list {
        display: flex;
        width: 1149px;
        height: 103px;
        margin-left: 79px;
        margin-top: 76px;
        text-align: center;
      }

      .numeric-item {
        width: 33%;
      }

      .numeric-list .num {
        font-size: 60px;
        font-family: Helvetica;
        font-weight: bold;
        color: #0074f4;
      }

      .numeric-list .dw {
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #0074f4;
        margin-left: 14px;
      }

      .numeric-list .text {
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #333333;
      }

      .border {
        border-right: solid 1px #c4c4c4;
      }
    }
  }
}

.overspread {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;
  display: block;
}

.pop-up {
  position: fixed;
  left: calc(50% - 481px);
  top: calc(50% - 260px);
  z-index: 900;
  width: 962px;
  height: 520px;
  background: url("../assets/pop-up.png") no-repeat;
  background-size: 100% 100%;

  .lateron {
    position: absolute;
    bottom: 53px;
    right: 71px;
    width: 130px;
    height: 49px;
    border: 1px solid #0060ff;
    border-radius: 24px;
    font-size: 20px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #0060ff;
    line-height: 49px;
    cursor: pointer;
  }
}
</style>
