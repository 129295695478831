<template>
  <div class="access">
    <div class="no-1"></div>
    <div class="no-2">
      <div class="mj"></div>
      <div class="textBox">
        <h1 class="title">智能硬件车辆道闸</h1>
        <div class="content">
          <p>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;好用点点智能车辆道闸管理系统支持停车场云管理、停车场数据云存储、收益报表云统计。采用的车牌识别一体机采用“立体高清车牌识别”技术，实
            时准确地自动识别出车牌的数字、字母、汉字字符，并直接给出识别结果（识别率大于99%），让车辆快速通过，实现车辆无障碍进出入停车场。
          </p>
          <p>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;系统支持各类电子支付（微信、支付宝、银联无感支付）；支持灵活费率设置、多种收费折扣、黑白名单管理；支持各类报表定义查询功能（固定车
            表，收费的日、月、年表，场内车辆表，原始报表，车辆的出入报表，收费汇总表）。
          </p>
          <p> 可应用于智慧停车场，实现车主全流程自助缴费提升车主停车体验，缓解交通拥堵，物业管理端降本增效；应用于智慧园区、智慧社区，实现访客车辆通
            行线上预约登记，闸机自行识别访客车辆，人-车-闸机一体化多通道便捷通行，提升VIP访客到访体验。</p>
        </div>
      </div>
    </div>
    <div class="no-3">
      <div class="left">
        <div class="box" @mousemove="active(0)" @mouseleave="leave">
          <div class="no">01</div>
          <div class="text">
            <p>车场、车辆高效管理</p>
            <p>可应用于智慧停车场，实现车主全流程自助缴费提升车主停车体验，
              缓解交通拥堵，物业管理端降本增效；</p>
          </div>
        </div>
        <div class="list">
          <div class="item" :class="activeIndex == 0 ? 'activeItem' : ''">
            <div>无人值守，减岗增效</div>
          </div>
          <div class="item" :class="activeIndex == 0 ? 'activeItem' : ''">
            <div>门禁联动，快速通行</div>
          </div>
          <div class="item" :class="activeIndex == 0 ? 'activeItem' : ''">
            <div>车牌识别率99.9%</div>
          </div>
          <div class="item" :class="activeIndex == 0 ? 'activeItem' : ''">
            <div>集中管控，异常开闸监测</div>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="box" @mousemove="active(1)" @mouseleave="leave">
          <div class="no">02</div>
          <div class="text">
            <p>自定义收费模式，支付灵活</p>
            <p>支持灵活费率设置、多种收费折扣、黑白名单管理；支持各类报表定
              义查询功能</p>
          </div>
        </div>
        <div class="list">
          <div class="item" :class="activeIndex == 1 ? 'activeItem' : ''">
            <div>月租/年租/临时车辆收费自定义</div>
          </div>
          <div class="item" :class="activeIndex == 1 ? 'activeItem' : ''">
            <div>无牌车辆扫码支付通行</div>
          </div>
          <div class="item" :class="activeIndex == 1 ? 'activeItem' : ''">
            <div>财务报表一键生成导出</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "UloveAccess",

  data() {
    return {
      activeIndex: null,
    };
  },

  mounted() { },

  methods: {
    // 鼠标移入事件
    active(e) {
      this.activeIndex = e
    },
    // 鼠标移出事件
    leave() {
      this.activeIndex = null
    },
  },
};
</script>

<style lang="less" scoped>
.access {
  width: 100%;
}

.no-1 {
  width: 100%;
  height: 1080px;
  background-image: url("../../assets/yingjian/sw.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.no-2 {
  display: flex;
  justify-content: center;
  position: relative;
  .no-1();
  background-image: url("../../assets/yingjian/sw-center-bg.png");

  .mj {
    width: 1596px;
    height: 719px;
    margin-top: 83px;
    background-image: url("../../assets/yingjian/sw-center.png");
    background-size: cover;
    background-repeat: no-repeat;
  }

  .textBox {
    width: 1308px;
    height: 408px;
    background-image: url("../../assets/yingjian/mj-textBg.png");
    background-size: 1412px 408px;
    background-repeat: no-repeat;
    position: absolute;
    top: 576px;
    padding: 40px 52px;

    .title {
      color: #222;
      font-size: 32px;
      text-align: left;
      line-height: 1;
    }

    .content {
      margin-top: 40px;
      text-align: left;
      line-height: 1.5;
      text-align: justify;
      text-align-last: left;
      font-size: 18px;
    }
  }
}

.no-3 {
  box-sizing: border-box;
  display: flex;
  justify-content: center;

  width: 100%;
  height: 1080px;
  box-sizing: border-box;
  background-image: url("../../assets/yingjian/sw-bo-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 190px;

  .box {
    width: 770px;
    height: 220px;
    background: #FFFFFF;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #222222;
    text-align: left;
  }

  .box:hover {
    background-image: url("../../assets/yingjian/sw-hover.png");
    background-repeat: no-repeat;
    background-size: 100%;
    color: #fff;
    cursor: pointer;
  }

  .box:first-child {
    margin-right: 50px;
  }

  .no {
    font-size: 80px;
    font-family: Helvetica;
    font-weight: bold;
    margin-right: 50px;
  }

  p {
    margin: 0;
  }

  p:first-child {
    font-size: 40px;
    font-family: PingFang SC;
    font-weight: bold;
    margin-bottom: 20px;
  }

  p:last-child {
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: 500;
    width: 540px;
    line-height: 30px;
  }

  .list {
    margin-top: 68px;

    .item {
      width: 768px;
      height: 100px;
      border: 1px solid #222222;
      border-radius: 10px;
      text-align: left;
      line-height: 100px;
      box-sizing: border-box;
      font-size: 24px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #222222;
      position: relative;
      margin-bottom: 30px;

      div {
        margin-left: 150px;
      }
    }



    .item:last-child {
      margin-bottom: 0;
    }

    .item::before {
      content: '';
      position: absolute;
      left: 48px;
      top: 32px;
      width: 50px;
      height: 37px;
      background-image: url("../../assets/yingjian/sw-g-black.png");
      background-repeat: no-repeat;
      background-size: 100%;
    }

    .activeItem {
      border-color: #165dff;
    }

    .activeItem::before {
      background-image: url("../../assets/yingjian/sw-g-blue.png");
    }
  }
}


.active {
  background-image: url("../../assets/yingjian/tipbg.png");
  background-size: 577px 277px;
  background-repeat: no-repeat;
  background-position: -33px -30px;

  .jieshao {
    color: #fff;
  }
}

.tipItem {
  display: flex;
  align-items: center;

  img {
    width: 80px;
    height: 80px;
    margin-left: 51px;
    margin-right: 42px;
  }

  .jieshao {
    height: 80px;
    font-size: 32px;
    width: 210px;
  }
}

.tipItem3 {
  .jieshao {
    width: 230px;
  }
}

.tipItem4 {
  .jieshao {
    width: 290px;
  }
}

.tipItem5 {
  text-align: left;

  .jieshao {
    width: 256px;
  }
}
</style>
