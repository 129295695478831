import { render, staticRenderFns } from "./topnavigation.vue?vue&type=template&id=6d23d888&scoped=true&"
import script from "./topnavigation.vue?vue&type=script&lang=js&"
export * from "./topnavigation.vue?vue&type=script&lang=js&"
import style0 from "./topnavigation.vue?vue&type=style&index=0&id=6d23d888&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d23d888",
  null
  
)

export default component.exports