<template>
  <div class="head">
    <!-- 头部图片 -->
    <div class="head-top"></div>
    <!-- 上 -->
    <div class="middle">
      <div class="middle-top">
        <div class="caput">
          <div class="img-1"></div>
          <div class="caput-size">公安情报平台</div>
          <div class="img-2"></div>
        </div>
        <div class="fundus">
          实现了情报搜集、加工、存储、检索和传递。在架构上，从底层的信息采集，到情报资源的整合、提炼，到上层情报的综合深度应用，形成了一个完整的公安信息化解决方案；在业务上，服务经侦、刑侦、治安、禁毒、巡警、国保、保卫、指挥部等各警种，既针对干警实战，提供专业情报研判服务，也兼顾领导指挥，为行动决策提供支持；在条线上，按照公安部大情报建设相关规范，构建部省市情报交换、共享、联动的协作机制，形成一套统筹规划，纵向贯通，立足处控，管理闭环的公安情报协作机制。
        </div>
      </div>
      <div class="middle-text">合作案例</div>
      <div class="img-flx">
        <div class="middle-flex">
          <img src="../../assets/security/hzsc.png" alt="" />
          <div class="flx-size">杭州市公安局上城区分局</div>
        </div>
        <div class="middle-flex">
          <img src="../../assets/security/qt.png" alt="" />
          <div class="flx-size">杭州市公安局钱塘区分局</div>
        </div>
      </div>
    </div>
    <!-- 中 -->
    <div class="medium">
      <div class="medium-one"></div>
      <div class="medium-two"></div>
    </div>
    <div class="base">
      <img src="../../assets/security/navigate.png" alt="" />
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      active: [
        {
          name: "楼凤模型",
          url: require("../../assets/police/yellow.png"),
        },
        {
          name: "出租房管理",
          url: require("../../assets/police/rent.png"),
        },
        {
          name: "人员轨迹",
          url: require("../../assets/police/map.png"),
        },
        {
          name: "常住人口",
          url: require("../../assets/police/populace.png"),
        },
        {
          name: "流动人口",
          url: require("../../assets/police/stream.png"),
        },
        {
          name: "重点人员",
          url: require("../../assets/police/reminder.png"),
        },
        {
          name: "在逃人员",
          url: require("../../assets/police/cracker.png"),
        },
        {
          name: "人员布控",
          url: require("../../assets/police/expansion.png"),
        },
        {
          name: "车辆布控",
          url: require("../../assets/police/vehicle.png"),
        },
        {
          name: "房哥模型",
          url: require("../../assets/police/home.png"),
        },
        {
          name: "吸毒模型",
          url: require("../../assets/police/takeDrugs.png"),
        },
        {
          name: "网约房管理",
          url: require("../../assets/police/chamber.png"),
        },
      ],
    };
  },
};
</script>
<style lang="less" scoped>
.head {
  .head-top {
    height: 1079px;
    background: url("../../assets/security/background.png") no-repeat;
    background-size: 100% 100%;
  }

  .middle {
    background: #fff;
    padding: 93px 165px 81px 165px;
  }

  .middle-top {
    width: 1599px;
    height: 303px;
    background: url("../../assets/security/twig.png") no-repeat;
    background-size: 100% 100%;
  }

  .caput {
    display: flex;
    justify-content: center;
    padding-top: 53px;
    padding-bottom: 41px;

    .img-1 {
      width: 38px;
      height: 36px;
      margin-right: 31px;
      background: url("../../assets/security/right-round.png") no-repeat;
      background-size: 100% 100%;
    }

    .caput-size {
      font-size: 32px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #ffffff;
      line-height: 30px;
    }

    .img-2 {
      width: 38px;
      height: 36px;
      background: url("../../assets/security/left-round.png") no-repeat;
      background-size: 100% 100%;
      margin-left: 31px;
    }
  }

  .fundus {
    width: 1487px;
    text-align: initial;
    margin-left: 59px;
    // margin: 0 53px 0 59px;
    font-size: 24px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #ffffff;
    text-indent: 2em;
    line-height: 30px;
  }

  .img-flx {
    display: flex;
    padding-top: 40px;
  }

  .middle-flex {
    position: relative;
  }

  .middle-flex:first-child {
    margin-right: 60px;
  }

  .flx-size {
    position: absolute;
    bottom: 38px;
    left: 58px;
    z-index: 99;
    font-size: 32px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #ffffff;
    line-height: 30px;
  }
}

.medium {
  display: flex;
  flex-wrap: wrap;
  padding: 104px 0 191px 0;
  padding-left: 161px;
  background: url("../../assets/security/back.png") no-repeat;
  background-size: 100% 100%;

  .medium-one {
    width: 1597px;
    height: 470px;
    background: url("../../assets/security/distribute.png") no-repeat;
    background-size: 100% 100%;
  }

  .medium-two {
    width: 1600px;
    height: 222px;
    background: url("../../assets/security/across.png") no-repeat;
    background-size: 100% 100%;
    margin-top: 93px;
  }
}


.base {
  padding: 149px 154px 188px 165px;
  background: url("../../assets/security/base.png") no-repeat;
  background-size: 100% 100%;

  img {
    width: 1600px;
    height: 743px;
  }
}

.middle-text {
  text-align: initial;
  margin-top: 91px;
  font-size: 32px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #222222;
  line-height: 30px;
}
</style>
