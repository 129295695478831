/*
 * @Author: flf flf@youlove.com.cn
 * @Date: 2022-12-27 09:47:41
 * @LastEditors: flf flf@youlove.com.cn
 * @LastEditTime: 2023-01-30 15:54:52
 * @FilePath: \ulove-web\src\router\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/home.vue'
import Access from '../views/hardware/Access.vue'
import Ladder from '../views/hardware/Ladder.vue'
import Charge from '../views/hardware/charge.vue'
import Switching from '../views/hardware/Switching.vue'

import Head from "../views/knowledge/head.vue";
import Intelligence from "../views/security/intelligence.vue";
import Platform from "../views/security/platform.vue";

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
    },
    {
        path: '/about',
        name: 'About',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    },
    {
        path: '/community',
        name: 'community',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/community.vue')
    },
    {
        path: '/park',
        name: 'park',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/park.vue')
    },
    {
      path: '/sociallink',
      name: 'sociallink',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ '../views/Sociallink/index.vue')
  },
    {
      path: '/hyy',
      name: 'hyy',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ '../views/hyy/index.vue')
    },
    {
        path: '/Yj',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/hardware/yj.vue'),
        children: [
            {
                path: 'Ladder',
                name: 'UloveLadder',
                component: Ladder
            },
            {
                path: 'Access',
                name: 'UloveAccess',
                component: Access
            },
            {
                path: 'Charge',
                component: Charge
            },
            {
                path: 'Switching',
                component: Switching
            },
        ]
    },
    {
        path: '/zhyj',
        name: 'zhyj',
        component: () => import('../views/emergency/index.vue')
      },
    {
        path: "/Police",
        component: () =>
          import("../views/knowledge/police.vue"),
        children: [
          {
            path: "Head",
            name: "UloveHead",
            component: Head,
          },
        ],
      },
      {
        path: "/Security",
        component: () =>
          import("../views/security/public.vue"),
        children: [
          {
            path: "Intelligence",
            name: "UloveIntelligence",
            component: Intelligence,
          },
          {
            path: "Platform",
            name: "UlovePlatform",
            component: Platform,
          },
        ],
      },
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
