<template>
  <div class="box" :class="scrollTop > 10 || this.secondmenusShow ? 'box-active' : ''">
    <div class="menus">
      <div class="logo-box">
        <img v-if="scrollTop > 10 || this.secondmenusShow" alt="logo" src="../assets/ylflogoc.png" />
        <img v-else alt="logo" src="../assets/ylflogo.png" />
      </div>
      <div class="nav-box">
        <div @click="goSY" class="font20" :class="topActive==1?'topActive':''">首页</div>
        <div class="font20" :class="topActive==2?'topActive':''" @mouseenter="mouseEnterNav('1', $event)" @click="goUrl('/zhyj',2)">
          解决方案
        </div>
        <div class="font20" :class="topActive==3?'topActive':''"  @click="goYJ" @mouseenter="mouseEnterNav('2', $event)">
          智能硬件
        </div>
        <div class="font20" :class="topActive==4?'topActive':''"  @click="goUrl('/hyy',4)">好用云</div>
        <div class="font20" :class="topActive==5?'topActive':''"  @mouseenter="mouseEnterNav('4', $event)" @click="goUrl('/about',5)">
          关于我们
        </div>
      </div>
      <div class="button" @click="probation">立即试用</div>
      <div class="tell-box">
        <img v-if="scrollTop > 10 || secondmenusShow" src="../assets/tellblack.png" alt="" />
        <img v-else src="../assets/tell.png" alt="" />
        <div>0571-89906536</div>
      </div>
    </div>
    <div class="second-menus" v-show="secondmenusShow" @mouseleave="mouseLeaveNav()">
      <div class="second-menusbox" :style="subStyle">
        <div>
          <div class="menusbox-item" v-for="(item, index) in this.secondmenus" :key="index">
            <div class="font18" style="width: 120px" @click="goUrl(item.url)" @mouseenter="mouseEnterNavson(item, $event)">
              {{ item.name }}
            </div>
          </div>
        </div>
        <div v-if="this.thirdmenus">
          <div  class="thirditem font18" v-for="(item, index) in this.thirdmenus" @click="goUrl(item.url)" :key="index">
            {{ item.sonname }}
          </div>
        </div>
      </div>
    </div>
    <div v-if="visble" class="overspread">
      <div class="pop-up">
        <div class="lateron" @click="probation">稍后试用</div>
      </div>
    </div>
    <div @click="regression" class="regression" v-if="scrollTop > 1800" :class="
  scrollTop > 1800 && scrollTop < 1900
    ? 'regression-top'
    : scrollTop > 1900 && scrollTop < 2000
      ? 'regression-twin'
      : ''
    ">
      <img src="../assets/regression.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      visble: false,
      scrollTop: 0,
      secondmenusShow: false,
      menusList: [
        [],
        [
          {
            name: "应急管理",
            son: [{ sonname: "应急指挥平台", url: "/zhyj" }],
          },
          {
            name: "公安信息化",
            son: [
              { sonname: "公安情报平台", url: "/Security/Intelligence" },
              { sonname: "公安合成作战指挥平台", url: "/Security/Platform" },
              { sonname: "警务通/在线责任区", url: "/Police/Head" },
            ],
          },
          {
            name: '智慧社区',
            url: "/community",
          },
          {
            name: '智慧园区',
            url: "/park",
          },
          {
            name: '社会联动',
            url: "/sociallink",
          },
        ],
        [
          {
            name: "智能充电桩",
            url: "/Yj/Charge",
          },
          {
            name: "智能梯控/阻车",
            url: "/Yj/Ladder",
          },
          {
            name: "智能门禁",
            url: "/Yj/Access",
          },
          {
            name: "智能车辆道闸",
            url: "/Yj/Switching",
          },
        ],
        [],
        [
          {
            name: "公司简介",
            url: "/about?target=top",
          },
          {
            name: "使命与愿景",
            url: "/about?target=sy",
          },
          {
            name: "荣誉资历",
            url: "/about?target=ry",
          },
          {
            name: "联系我们",
            url: "/about?target=hz",
          },
          {
            name: "业务合作",
            url: "/about?target=hz",
          },
        ],
      ],
      secondmenus: [],
      thirdmenus: [],
      subStyle: "",
      topActive:0,
    };
  },
  created(){
    console.log('顶部导航-----路由参数',this.$route)
    this.topActive=this.$route.query.ymid
  },
  mounted() {
    window.scrollTo(0, 0);
    console.log("secondmenusShow", this.secondmenusShow);
    window.addEventListener("scroll", this.handleScroll); // 监听页面滚动
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    probation() {
      this.visble = !this.visble;
    },
    regression() {
      window.scrollTo(0, 0);
    },
    // 页面跳转
    goUrl(url,val) {
      // if(val){
      //   this.topActive=val
      // }
      if (url.indexOf(this.$route.path) > -1 && this.$route.path != "/") {
        let id = "#" + url.split("=")[1];
        document.querySelector(id).scrollIntoView({
          behavior: "smooth",
        });
      } else {
        window.scrollTo(0, 0);
        this.$router.push({
          path: url,
          query: { ymid: val }
        });
      }
    },
    // 获取页面滚动距离
    handleScroll() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      this.scrollTop = scrollTop;
      // console.log(scrollTop, '页面滚动距离')
    },
    // 鼠标移入一级导航时获取及显示二级导航
    mouseEnterNav(val, e) {
      // console.log('鼠标移入',val,e,)
      // console.log('鼠标在一级菜单')
      this.thirdmenus = [];
      let itemX = e.target.offsetLeft;
      //let itemW = e.target.offsetWidth
      //let screenWidth = document.documentElement.clientWidth
      this.subStyle = `left: ${itemX}px`;
      this.secondmenus = this.menusList[val];
      console.log("鼠标移入后list", this.secondmenus);
      this.secondmenusShow = true;
    },
    // 鼠标移入二级导航时获取及显示三级导航
    mouseEnterNavson(val, e) {
      console.log("鼠标移入二级菜单", val, e);
      this.thirdmenus = val.son;
      console.log("三级菜单获取", this.thirdmenus);
    },
    // 鼠标移出一级导航
    mouseLeaveNav() {
      console.log("鼠标出入");
      this.secondmenusShow = false;
    },
    goYJ() {
      this.$router.push({
        path: "/Yj/Access",
        query: { ymid: 3 }
      });
    },
    goTK() {
      this.$router.push({
        path: "/Yj/Ladder",
      });
    },
    goCharge() {
      this.$router.push({
        path: "/Yj/Charge",
      });
    },
    goSY() {
      // this.topActive=1
      this.$router.push({
        path: "/",
        query: { ymid: 1 }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.font20{
  font-size: 20px !important;
}
.topActive{
  font-size: 20px !important;
  color: #1e80ff;
}
.font18{
  font-size: 18px !important;
}
.box-active {
  background: #fff !important;
  color: #333333 !important;
}

.box {
  // overflow: hidden;
  //width: 1920px;
  width: 100%;
  //background: rgba(0,0,0,.5);
  background: transparent;
  padding-top: 58px;
  color: #ffffff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;

  .menus {
    display: flex;
    // justify-content: space-around;
    width: 100%;
    position: relative;
    top: -18px;

    .logo-box {
      margin-left: 3%;
      width: 10%;
      margin-top: -5px;
      height: 34px;
      display: flex;
      background: transparent;
    }

    .nav-box {
      width: 45%;
      font-size: 18px;
      font-family: PingFang SC;
      font-weight: bold;
      display: flex;
      justify-content: space-around;
      cursor: pointer;
      margin-left: 21.2%;
    }

    .nav-box div:hover {
      color: #1e80ff;
    }
    .nav-box div:active{
      color: #1e80ff;
    }
    .button {
      width: 7%;
      height: 46px;
      background: #0063be;
      border-radius: 23px;
      font-size: 18px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #ffffff;
      line-height: 46px;
      margin-top: -12px;
      margin-right: 80px;
      cursor: pointer;
    }

    .tell-box {
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 500;
      display: flex;
      align-content: center;

      img {
        margin-top: 3px;
        width: 13px;
        height: 16px;
        margin-right: 8px;
      }

      div {}
    }
  }

  .second-menus {
    width: 100%;
    height: 230px;
    background: #fff;
    z-index: 999;
    color: #333333;
  }

  .second-menus .second-menusbox {
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 500;
    position: absolute;
    // background: #0063BE;
    text-align: left;
    display: flex;
    cursor: pointer;

    .menusbox-item {
      display: flex;
      padding: 10px 0;
    }

    .menusbox-item:hover {
      color: #1e80ff;
    }

    .thirditem {
      padding: 10px 15px;
      background: url("../assets/right.png") no-repeat;
      background-position: 100% 50%;
    }

    .thirditem:hover {
      border-bottom: 1px #0264bc solid;
      color: #0264bc;
      background: url("../assets/rightbule.png") no-repeat;
      background-position: 100% 50%;
    }
  }
}

.overspread {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;
  display: block;
}

.pop-up {
  position: fixed;
  left: calc(50% - 481px);
  top: calc(50% - 260px);
  z-index: 900;
  width: 962px;
  height: 520px;
  background: url("../assets/pop-up.png") no-repeat;
  background-size: 100% 100%;

  .lateron {
    position: absolute;
    bottom: 53px;
    right: 71px;
    width: 130px;
    height: 49px;
    border: 1px solid #0060ff;
    border-radius: 24px;
    font-size: 20px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #0060ff;
    line-height: 49px;
    cursor: pointer;
  }
}

.regression-top {
  opacity: 0.3;
}

.regression-twin {
  opacity: 0.8;
}

.regression {
  position: absolute;
  top: 800px;
  right: 2%;
}
</style>
