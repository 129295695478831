<template>
  <div class="charge">
    <img class="banner1 banner" src="../../assets/yingjian/chargeBanner1.png" alt="" />
    <div class="banner2 banner">
      <img class="jsc" src="../../assets/yingjian/chargeJsc.png" alt="" />
      <div class="jscJs">
        <div class="jscText">
          <div class="jscTitle">智能充电桩</div>
          <div class="jscContent">
            是杭州尤拉夫科技有限公司与浙江大学、政府及其他科研院
            所联合研发的创新应用。平台采用大数据、物联网、云计算
            等技术，搭建了全国第一个安全用电学习算法，服务于充电
            桩设备保护及充电安全监测，是安全、智慧的“第三代”智能充电桩。
          </div>
        </div>
        <div class="line1"></div>
        <div class="jscText">
          <div class="jscTitle">人工智能(AI)电池体检算法</div>
          <div class="jscContent">
            采用大数据技术对电池历史数据特征进行提取和分析，同时
            通过机器学习算法建立学习模型，从而实现电瓶车安全充电
            监测。将车辆电池异常情况实时提醒车主，并推送给政府相
            关部门，便于车主及消防人员闭环处置消除因电瓶车电池质
            量问题而引起的消防隐患。
          </div>
        </div>
      </div>
    </div>
    <div class="banner banner3">
      <div class="ways">
        <div class="waystext">
          <h1 style="margin-bottom: 32px">应用方向</h1>
          <div class="waysSecond">
            <div>自助电池更换与回收</div>
            <div>出行互联</div>
          </div>
          <div class="waysSecond">
            <div>充电监测</div>
            <div>消防提醒</div>
          </div>
          <div class="waysSecond">电池优化</div>
          <p style="margin-top: 40px; line-height: 2.2">
            充电桩以微信小程序、后台管理两种终端进
            行监控和管理，从而实现“网络化、智能化、
            无人化”的科学管理模式，使充电桩运行更为
            稳定，管理更方便，结算更简单。
          </p>
        </div>
        <div class="banner3img">
          <img src="../../assets/yingjian/chargeBanner3Img.png" alt="" />
        </div>
      </div>
      <div class="banner3Right">
        <div class="rightTop">
          <div class="rightTitle">实现“网络化、智能化、无人化”的</div>
          <div class="rightTitle">
            科学管理模式，使充电桩运行更稳定，管理更方便，结算更简单
          </div>
        </div>
        <div class="rightBottom">
          <div class="rightBottomItem">
            <img src="../../assets/yingjian/user.png" alt="" />
            <div class="itemTitle">用户端 · 微信小程序</div>
            <div class="itemBottom">
              <div class="rightItem">
                <div>按图索桩</div>
                <div>扫码充电</div>
                <div>刷卡充电</div>
                <div>设备查看</div>
                <div>设备选择</div>
              </div>
              <div class="rightItem">
                <div>充电记录</div>
                <div>订单详情</div>
                <div>功率曲线</div>
                <div>钱包充值</div>
                <div>余额查询</div>
              </div>
              <div class="rightItem">
                <div>申请办卡</div>
                <div>充电卡管理</div>
              </div>
            </div>
          </div>
          <div class="rightBottomItem">
            <img src="../../assets/yingjian/staff.png" alt="" />
            <div class="itemTitle">管理端 · 管家小程序</div>
            <div class="itemBottom">
              <div class="rightItem">
                <div>收益查询</div>
                <div>收益提现</div>
                <div>订单查询</div>
                <div>管理小区</div>
                <div>分成比例查看</div>
              </div>
              <div class="rightItem">
                <div>设备情况查看</div>
                <div>电量消耗查询</div>
              </div>
            </div>
          </div>
          <div class="rightBottomItem">
            <img src="../../assets/yingjian/computer.png" alt="" />
            <div class="itemTitle">可视化管理平台</div>
            <div class="itemBottom">
              <div class="rightItem">
                <div>收益查询</div>
                <div>订单查询</div>
                <div>提现管理</div>
                <div>设备管理</div>
                <div>物业管理</div>
              </div>
              <div class="rightItem">
                <div>小区管理</div>
                <div>运营商管理</div>
                <div>充电卡管理</div>
                <div>设备心跳管理</div>
                <div>异常信息管理</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="banner banner4">
      <div class="top4">
        <div class="top4Left">
          <div class="top4LeftItem">
            <img src="../../assets/yingjian/banner4TopLeft1.png" alt="" />
            <div>功率检测 · 电池体检</div>
          </div>
          <div class="top4LeftItem">
            <img src="../../assets/yingjian/banner4TopLeft2.png" alt="" />
            <div>故障信息 · 自动推送</div>
          </div>
          <div class="top4LeftItem">
            <img src="../../assets/yingjian/banner4TopLeft3.png" alt="" />
            <div>语音提示 · 性能稳定</div>
          </div>
          <div class="top4LeftItem">
            <img src="../../assets/yingjian/banner4TopLeft4.png" alt="" />
            <div>自定义设置 · 不同功率不同价格</div>
          </div>
          <div class="top4LeftItem">
            <img src="../../assets/yingjian/banner4TopLeft5.png" alt="" />
            <div>空载断电 · 保障安全</div>
          </div>
          <div class="top4LeftItem">
            <img src="../../assets/yingjian/banner4TopLeft6.png" alt="" />
            <div>远程监控 · 方便运维</div>
          </div>
          <div class="top4LeftItem">
            <img src="../../assets/yingjian/banner4TopLeft7.png" alt="" />
            <div>充满自停 · 保护电池</div>
          </div>
          <div class="top4LeftItem">
            <img src="../../assets/yingjian/banner4TopLeft8.png" alt="" />
            <div>高温断电 · 避免火灾</div>
          </div>
        </div>
        <div class="top4Right">
          <h2>截至目前，落地投入使用的</h2>
          <h2>智能充电桩已有</h2>
          <h1 class="top4Right-size">300+</h1>
        </div>
      </div>

      <div class="swiper-container">
        <div class="swiper-wrapper" style="width: 820px">
          <div class="swiperItem swiper-slide" v-for="(item, index) in listData" :key="index">
            <img :src="require('../../assets/yingjian/1/' + (index + 1) + '.png')" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import vueSeamlessScroll from "vue-seamless-scroll";
import "swiper/dist/js/swiper";
import "swiper/dist/css/swiper.css";
import Swiper from "swiper";
export default {
  name: "UloveCharge",
  // components: {
  //   vueSeamlessScroll,
  // },
  data() {
    return {
      x: 0,
      move: 0,
      isDown: false,
      listData: [
        { src: "../../assets/yingjian/1/1.png" },
        { src: "../../assets/yingjian/1/2.png" },
        { src: "../../assets/yingjian/1/3.png" },
        { src: "../../assets/yingjian/1/4.png" },
        { src: "../../assets/yingjian/1/5.png" },
        { src: "../../assets/yingjian/1/6.png" },
        { src: "../../assets/yingjian/1/7.png" },
        { src: "../../assets/yingjian/1/8.png" },
      ],
    };
  },

  mounted() {
    new Swiper(".swiper-container", {
      direction: "horizontal", // 垂直切换选项
      //mousewheel: true, //滚轮
      autoplay: {
        //自动开始
        delay: 500, //时间间隔
        action: false, //*手动操作轮播图后不会暂停*
      },
      slidesPerView: 2,
      centeredSlides: true,
      loop: true, // 循环模式选项
    });
  },

  methods: {},
};
</script>

<style lang="less" scoped>
.charge {
  line-height: 1;
  width: 100%;
}

.banner {
  width: 100%;
  height: 1080px;
}

.banner2 {
  background-image: url("../../assets/yingjian/mj-no3Bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  top: 0;
  left: 0;

  .jsc {
    width: 1417px;
    height: 685px;
    margin-top: 110px;
  }

  .jscJs {
    text-align: center;
    width: 1220px;
    height: 342px;
    background-image: linear-gradient(to top right, #e4f1ff, #fbfafa, #e9f3ff);
    position: absolute;
    bottom: 80px;
    border-radius: 10px;
    border: 1px solid #1367cc;
    display: flex;
    justify-content: space-between;
  }
}

.line1 {
  width: 0;
  height: 203px;
  border-left: 1px solid #979da5;
  margin-top: 66px;
}

.jscText {
  width: 467px;
  text-align: left;
  margin: 54px 70px 0;

  .jscTitle {
    font-size: 32px;
    color: #222;
    line-height: 1;
  }

  .jscContent {
    font-size: 18px;
    color: #222;
    margin-top: 40px;
    line-height: 1.8;
    text-align: justify;
    text-align-last: left;
  }
}

.banner3 {
  background-color: #eff5f9;
  display: flex;

  .ways {
    width: 477px;
    height: 838px;
    margin: 134px 0 0 124px;
    background-image: url("../../assets/yingjian/chargebanner3.png");
    background-size: cover;

    .waystext {
      text-align: left;
      color: #fff;
      margin: 72px 70px 0;
      text-align: justify;
      text-align-last: left;
      font-size: 18px;
    }
  }
}

.waysSecond {
  width: 272px;
  margin-bottom: 20px;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.banner3img {
  width: 362px;
  height: 255px;
  margin-left: 61px;
  margin-top: 55px;

  img {
    width: 362px;
    height: 255px;
  }
}

.banner3Right {
  height: 838px;
  width: 1156px;
  margin-top: 134px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .rightTop {
    padding-top: 86px;

    .rightTitle {
      font-size: 32px;
      color: #333;
      margin-bottom: 20px;
      font-weight: bold;
      color: #333;
    }
  }

  .rightBottom {
    width: 1156px;
    height: 450px;
    margin-top: 154px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .rightBottomItem {
      width: 375px;
      height: 450px;
      background-color: #fff;
      border-radius: 10px;
    }
  }
}

.rightBottomItem {
  img {
    width: 64px;
    height: 64px;
    margin-top: 44px;
  }

  .itemTitle {
    font-size: 32px;
    color: #1c7dff;
    font-weight: bold;
    margin-top: 54px;
    line-height: 1;
  }

  .itemBottom {
    margin-top: 41px;
    display: flex;
    align-items: center;
    justify-content: space-around;

    .rightItem {
      height: 178px;
      line-height: 2.3;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
    }
  }
}

.banner4 {
  background-image: url("../../assets/yingjian/chargeBanner4Bg.png");
  background-size: cover;
  background-repeat: no-repeat;

  .top4 {
    box-sizing: border-box;
    width: 100%;
    height: 404px;
    padding-top: 26px;
    display: flex;
    justify-content: space-between;
  }
}

.top4Right {
  box-sizing: border-box;
  text-align: right;
  height: 204px;
  margin-right: 168px;
  margin-top: 121px;
}

.top4Right-size {
  font-size: 60px;
}

.top4Left {
  box-sizing: border-box;
  height: 256px;
  width: 960px;
  margin-left: 162px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  .top4LeftItem {
    margin-top: 60px;
    height: 128px;
    width: 240px;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 0;

    img {
      width: 80px;
      height: 80px;
    }

    div {
      margin-top: 30px;
      font-size: 18px;
      color: #333;
      line-height: 1;
    }
  }
}

.swiper-container {
  width: 1920px;
  height: 500px;
  margin-top: 118px;
}

.swiper-slide {
  width: 820px !important;
  height: 500px;
  border-radius: 30px;
  background-color: #fff;
  margin-right: 30px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
  }
}

.warp {
  width: 100%;
  height: 500px;
  margin: 120px auto;
  overflow: hidden;

  ul {
    list-style: none;
    padding: 0;
    margin: 0 auto;

    &.ul-item {
      display: flex;

      .li-item {
        width: 820px;
        height: 500px;
        margin-right: 20px;
        line-height: 120px;
        border-radius: 20px;
        background-color: #999;
        color: #fff;
        text-align: center;
        font-size: 30px;
      }
    }
  }
}

// .mySwiper {
//   width: 1920px;
//   height: 500px;
//   // background-color: red;

//   .floatBox {
//     position: relative;
//     overflow: hidden;

//     .box {
//       position: absolute;

//       .swiperItem {
//         float: left;
//         width: 820px;
//         height: 500px;
//         border-radius: 30px;
//         background-color: green;
//       }
//     }
//   }
// }
</style>
