<template>
  <div class="head">
    <!-- 头部图片 -->
    <div class="head-top"></div>
    <!-- 上 -->
    <div class="middle">
      <div class="middle-top">
        <div class="caput">
          <div class="img-1"></div>
          <div class="caput-size">警务通/在线责任区</div>
          <div class="img-2"></div>
        </div>
        <div class="fundus">
          警务通app/在线责任区：贯通市（区县）域、街道/乡镇、社区/村居、小区四级架构，具体到某一户下的居住人员信息。以警情情况、敏感人
          员为触点，基层民警可以分析研究警情高发多发区域和类型，组织开展精准布防，切实提高见警率、管事率和震慑力，有效挤压犯罪空间。
        </div>
      </div>
    <!-- 合作案例 -->
    <div class="case">
      <div class="title">合作案例</div>
      <div class="row">
        <div class="item">
          <img src="../../assets/police/bureau.png" alt="" />
          <p>杭州市公安局上城区分局</p>
        </div>
        <div class="item">
          <img src="../../assets/police/gz.png" alt="" />
          <p>赣州市公安局开发区分局</p>
        </div>
      </div>
    </div>
    </div>
    <div class="middle">
      <div class="img-flx">
        <img src="../../assets/first-one.png" alt="" />
        <img src="../../assets/twin.png" alt="" />
        <img src="../../assets/three.png" alt="" />
        <img src="../../assets/four.png" alt="" />
      </div>
    </div>
    <!-- 中 -->
    <div class="medium">
      <div v-for="(item, index) in active" :key="index" class="medium-item" :class="index > 5 ? 'mediums-item' : ''">
        <img :src="item.url" alt="" />
        <div class="medium-size">{{ item.name }}</div>
      </div>
    </div>
    <div class="emphasis">
      <div class="emphasis-size">重点人员管控预警</div>
      <div class="emphasis-top">
        <div class="emphasis-item">
          <div class="first">01</div>
          <div class="text">
            通过人脸识别系统“识人”捕捉，同时平台联动报警，通知人为管控。
          </div>
        </div>
        <div class="emphasis-item">
          <div class="first">02</div>
          <div class="text">
            通过硬件联动平台，实现活动轨迹记录与查询，挖掘轨迹数据，对人员异常行为及时预警
          </div>
        </div>
      </div>
      <img src="../../assets/police/trajectory.png" alt="" />
    </div>
    <div class="base">
      <div class="caput">
        <div class="img-1"></div>
        <div class="caput-size">在线责任区驾驶舱效果图</div>
        <div class="img-2"></div>
      </div>
      <img src="../../assets/police/navigate.png" alt="" />
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      active: [
        {
          name: "楼凤模型",
          url: require("../../assets/police/yellow.png"),
        },
        {
          name: "出租房管理",
          url: require("../../assets/police/rent.png"),
        },
        {
          name: "人员轨迹",
          url: require("../../assets/police/map.png"),
        },
        {
          name: "常住人口",
          url: require("../../assets/police/populace.png"),
        },
        {
          name: "流动人口",
          url: require("../../assets/police/stream.png"),
        },
        {
          name: "重点人员",
          url: require("../../assets/police/reminder.png"),
        },
        {
          name: "在逃人员",
          url: require("../../assets/police/cracker.png"),
        },
        {
          name: "人员布控",
          url: require("../../assets/police/expansion.png"),
        },
        {
          name: "车辆布控",
          url: require("../../assets/police/vehicle.png"),
        },
        {
          name: "房哥模型",
          url: require("../../assets/police/home.png"),
        },
        {
          name: "吸毒模型",
          url: require("../../assets/police/takeDrugs.png"),
        },
        {
          name: "网约房管理",
          url: require("../../assets/police/chamber.png"),
        },
      ],
    };
  },
};
</script>
<style lang="less" scoped>
.head {
  .head-top {
    height: 1079px;
    background: url("../../assets/police.jpg") no-repeat;
    background-size: 100% 100%;
  }

  .middle {
    background: #fff;
    padding: 93px 165px 81px 165px;
    .case {
      text-align: left;
      width: 1601px;
      .title {
        text-align: left;
        font-size: 32px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #222222;
        margin-bottom: 40px;
      }

      .row {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .row img {
        width: 770px;
        height: 380px;
      }

      .row .item {
        position: relative;
      }

      .row p {
        font-size: 32px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 30px;
        position: absolute;
        width: 770px;
        display: inline-block;
        bottom: 40px;
        left: 66px;
      }
    }
  }

  .middle-top {
    // width: 1599px;
    height: 277px;
    background: url("../../assets/twig.png") no-repeat;
    background-size: 100% 100%;
    margin-bottom: 85px;
  }

  .caput {
    display: flex;
    justify-content: center;
    padding-top: 53px;
    padding-bottom: 41px;

    .img-1 {
      width: 38px;
      height: 36px;
      margin-right: 31px;
      background: url("../../assets/right-round.png") no-repeat;
      background-size: 100% 100%;
    }

    .caput-size {
      font-size: 32px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #333333;
      line-height: 30px;
    }

    .img-2 {
      width: 42px;
      height: 31px;
      background: url("../../assets/left-round.png") no-repeat;
      background-size: 100% 100%;
      margin-left: 31px;
    }
  }

  .fundus {
    text-align: left;
    margin: 0 60px;
    font-size: 24px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 30px;
  }

  .img-flx {
    display: flex;
    justify-content: space-around;
    padding-top: 62px;
  }
}

.medium {
  display: flex;
  flex-wrap: wrap;
  padding: 234px 0;
  padding-left: 166px;
  background: url("../../assets/police/background.png") no-repeat;
  background-size: 100% 100%;

  .medium-item {
    margin-right: 79px;
    img {
      width: 200px;
      height: 200px;
    }
  }
  .mediums-item {
    margin-top: 103px;
  }
  .medium-size {
    font-size: 24px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 30px;
    margin-top: 31px;
  }
}

.emphasis {
  padding: 98px 156px 65px 164px;
  text-align: initial;
  background: url("../../assets/police/emphasis.png") no-repeat;
  background-size: 100% 100%;

  img {
    margin-top: 80px;
    margin-left: 136px;
  }
}

.emphasis-size {
  font-size: 40px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #333333;
  line-height: 30px;
}

.emphasis-top {
  margin-top: 40px;
  display: flex;
  justify-content: space-between;

  .emphasis-item {
    max-width: 750px;
    background: #ffffff;
    border: 1px solid #1c7dff;
    border-radius: 20px;
    display: flex;
    padding: 61px 0;

    .first {
      font-size: 50px;
      font-family: Helvetica;
      font-weight: bold;
      color: #333333;
      line-height: 72px;
      margin-right: 30px;
      margin-left: 41px;
    }

    .text {
      font-size: 24px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #333333;
      margin-right: 58px;
      line-height: 36px;
    }
  }
}

.base {
  padding: 70px 0 63px 0;

  .caput {
    padding-top: 0;
    padding-bottom: 63px;
  }
}
</style>
