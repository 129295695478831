<template>
  <div class="access">
    <div class="no-1"></div>
    <div class="no-2">
      <div class="mj"></div>
      <div class="textBox">
        <h1 class="title">好用点点智能门禁系统</h1>
        <div class="content">
          <p>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;系统对接公安内网全国人口库等数据，通过智能门禁的部署对人员进行数据信息采集比对，实现“动态”通行码、健康码等多码合一。后台管理端具有人员轨迹追踪与大数据分析功能，对公安重点人员、异常人员等预警提醒，智能追溯，有效控制治安事件发生源，为智慧社/园区赋能。
          </p>
          <p>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;同时系统具备微信小程序访客邀请和访客来访预约功能，访客信息智能核验与分析记录，无需安装实体访客机。联动好用点点智能门禁、车辆道闸、梯控，管理端还可授权访客人-车-闸机便捷通行以及电梯直达指定楼层，轻松实现流动人员精准管理。
          </p>
        </div>
      </div>
    </div>
    <div class="no-3">
      <div class="no3-header">
        <img src="../../assets/yingjian/left-cercle.png" alt="" />
        <div class="title">好用点点门禁系统支持</div>
        <img src="../../assets/yingjian/right-cercle.png" alt="" />
      </div>
      <div class="tips">
        <div class="tipItem1 tipItem" @mouseenter="active('1')" :class="active1.indexOf('1') != -1 ? 'active' : ''"
          @mouseleave="leave()">
          <img v-if="active1.indexOf('1') != -1" src="../../assets/yingjian/item1active.png" alt="" />
          <img v-else src="../../assets/yingjian/item1.png" alt="" />
          <div class="jieshao">双目活体检测人员精准辨别</div>
        </div>
        <div class="tipItem2 tipItem" @mouseenter="active('2')" :class="active1.indexOf('2') != -1 ? 'active' : ''"
          @mouseleave="leave()">
          <img v-if="active1.indexOf('2') == -1" src="../../assets/yingjian/item2.png" alt="" />
          <img v-else src="../../assets/yingjian/item2active.png" alt="" />
          <div class="jieshao">一键邀请访客自助入园进门</div>
        </div>
        <div class="tipItem3 tipItem" @mouseenter="active('3')" :class="active1.indexOf('3') != -1 ? 'active' : ''"
          @mouseleave="leave()" style="margin-right: 0">
          <img v-if="active1.indexOf('3') == -1" src="../../assets/yingjian/item3.png" alt="" />
          <img v-else src="../../assets/yingjian/item3active.png" alt="" />
          <div class="jieshao">开门方式多样化支持脱机识别</div>
        </div>

        <div class="tipItem5 tipItem" @mouseenter="active('5')" :class="active1.indexOf('5') != -1 ? 'active' : ''"
          @mouseleave="leave()">
          <img v-if="active1.indexOf('5') == -1" src="../../assets/yingjian/item5.png" alt="" />
          <img v-else src="../../assets/yingjian/item5active.png" alt="" />
          <div class="jieshao">
            <div>通行记录数字化</div>
            <div>智能追溯人员信息</div>
          </div>
        </div>
      </div>
    </div>
    <div class="no-4">
      <div class="mind"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "UloveAccess",

  data() {
    return {
      active1: [],
    };
  },

  mounted() { },

  methods: {
    // 鼠标移入事件
    active(e) {
      if (this.active1.indexOf(e) == -1) {
        this.active1.push(e);
      } else {
        return;
      }
    },
    // 鼠标移出事件
    leave() {
      if (this.active1.length >= 1) {
        this.active1.pop();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.access {
  width: 100%;
}

.no-1 {
  width: 100%;
  height: 1080px;
  background-image: url("../../assets/yingjian/accessBg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.no-2 {
  display: flex;
  justify-content: center;
  position: relative;
  .no-1();
  background-image: url("../../assets/yingjian/mj-no3Bg.png");

  .mj {
    width: 1596px;
    height: 719px;
    margin-top: 83px;
    background-image: url("../../assets/yingjian/mj.png");
    background-size: cover;
    background-repeat: no-repeat;
  }

  .textBox {
    width: 1308px;
    height: 408px;
    background-image: url("../../assets/yingjian/mj-textBg.png");
    background-size: 1412px 408px;
    background-repeat: no-repeat;
    position: absolute;
    top: 576px;
    padding: 40px 52px;

    .title {
      color: #222;
      font-size: 32px;
      text-align: left;
      line-height: 1;
    }

    .content {
      margin-top: 40px;
      text-align: left;
      line-height: 1.5;
      text-align: justify;
      text-align-last: left;
      font-size: 18px;
    }
  }
}

.no-3 {
  padding-top: 144px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  flex-direction: column;
  .no-1();
  background-image: url("../../assets/yingjian/mj-no2Bg.png");

  .no3-header {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 38px;
      height: 36px;
    }

    img:last-child {
      width: 42px;
      height: 31px;
    }

    .title {
      font-size: 40px;
      color: #222;
      margin: 0 30px;
    }
  }

  .tips {
    width: 1590px;
    margin-top: 147px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .tipItem {
      width: 771px;
      height: 212px;
      background-color: #fff;
      border-radius: 20px;
      border: 2px solid #1c7dff;
      margin-bottom: 60px;
      cursor: pointer;
      // margin-right: 24px;
    }
  }
}

.no-4 {
  display: flex;
  justify-content: center;
  .no-1();
  background-image: url("../../assets/yingjian/mj-no4Bg.png");

  .mind {
    margin-top: 140px;
    width: 1592px;
    height: 630px;
    background-image: url("../../assets/yingjian/mind.png");
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.active {
  width: 771px;
  height: 212px;
  background-image: url("../../assets/yingjian/tipbg.png");
  background-size: cover;
  background-repeat: no-repeat;
  // background-position: -33px -30px;

  .jieshao {
    color: #fff;
  }
}

.tipItem {
  display: flex;
  align-items: center;

  img {
    width: 80px;
    height: 80px;
    margin-left: 51px;
    margin-right: 42px;
  }

  .jieshao {
    height: 80px;
    font-size: 32px;
    width: 210px;
    text-align: left;
  }
}

.tipItem3 {
  .jieshao {
    width: 230px;
  }
}

.tipItem4 {
  .jieshao {
    width: 290px;
  }
}

.tipItem5 {
  text-align: left;

  .jieshao {
    width: 260px;
  }
}
</style>
