<template>
  <div class="ladder">
    <div class="banner banner1"></div>
    <div class="banner banner2">
      <div class="ladderBanner2Content">
        <div class="ladderBanner2center">
          <div class="banner2Title">好用点点电梯控制系统</div>
          <div class="banner2Content">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;在疫情防控常态化背景下，针对电梯按键交叉感染场景，好用点点智能电梯控制系统支持刷卡、扫二维码识别楼层乘坐电梯，实现无接触智能乘梯有
            效减少感染风险同时支持黑白名单管理，有效限制无权乘梯人员、闲杂人员，降低电梯使用频率，节省电梯能耗；支持VIP乘梯模式，通过动态人脸识别、
            远距离感应无感智能识别技术，快速识别VIP用户身份为其预约乘梯，无需VIP用户等待，满足住户安全和物业服务多场景化需求。
          </div>
        </div>
      </div>
    </div>
    <div class="banner banner3">
      <div class="banner3Content">
        <div class="banner3ContentItem" v-for="(item, index) in banner3List" :key="index"
          :style="{ backgroundImage: 'url(' + item.url + ')' }" @mouseenter="maskShow(index)" @mouseleave="maskMove">
          <div class="banner3Mask" v-if="active == index"></div>
          <div class="banner3ItemTitle">{{ item.title }}</div>
          <div class="banner3ItemContent">{{ item.content }}</div>
        </div>
      </div>
    </div>
    <div class="banner banner2 banner4">
      <div class="ladderBanner2Content ladderBanner4Content">
        <div class="ladderBanner2center">
          <div class="banner2Title">好用点点电梯阻车系统</div>
          <div class="banner2Content">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;近年来，电动车违规停放、楼道充电导致的火灾屡屡发生，好用点点智能电梯阻车系统基于AI深度学习及海量人车数据校验算法，能够进行精准人车识别，实时阻拦电动车上梯。既保障了电梯和乘梯人的安全，更提高了物业监管水平和效率，从源头上杜绝电动车进楼入户导致的火灾发生。
          </div>
        </div>
      </div>
    </div>
    <div class="banner banner5">
      <div class="banner5Center">
        <div class="banner5Item" v-for="(item, index) in banner5List" :key="index">
          <img :src="item.url" alt="" />
          <div class="banner5Text">{{ item.text }}</div>
        </div>
      </div>
    </div>
    <div class="banner banner6">
      <div class="banner6Center">
        <div class="banner6Item" v-for="(item, index) in banner6List" :key="index" :style="{
  backgroundImage: 'url(' + item.url + ')',
  marginRight: index % 3 == 2 ? '0' : '30px',
  marginTop: index > 2 ? '30px' : '0',
}">
          <div class="banner6ItemFooter">
            <div class="Num">{{ "0" + (index + 1) }}</div>
            <div class="Text">{{ item.text }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { url } from 'inspector';

export default {
  name: "UloveLadder",

  data() {
    return {
      active: undefined,
      banner3List: [
        {
          url: require("../../assets/yingjian/ladderBanner3one.png"),
          title: "智能匹配乘梯楼层 安全管控乘梯人员",
          content:
            "根据住户实际居住楼层划分访问权限，对进出电梯的用户进行有效管理。",
        },
        {
          url: require("../../assets/yingjian/ladderBanner3Two.png"),
          title: "一键邀请访客 自助乘梯",
          content:
            "访客经授权后方能乘梯，杜绝未授权人员使用电梯，减少电梯损耗，增强场所安全性。",
        },
        {
          url: require("../../assets/yingjian/ladderBanner3Three.png"),
          title: "无接触乘梯方式   防止病毒的传播",
          content: "",
        },
        // {
        //   url: require("../../assets/yingjian/ladderBanner3Four.png"),
        //   title: "智能匹配乘梯楼层，安全管控乘梯人员",
        //   content:
        //     "根据住户实际居住楼层划分访问权限，对进出电梯的用户进行有效管理。",
        // },
        {
          url: require("../../assets/yingjian/ladderBanner3Five.png"),
          title: "乘梯记录数字化   智能追溯人员信息",
          content:
            "规范住户、访客信息登记。",
        },
      ],
      banner5List: [
        {
          url: require("../../assets/yingjian/ladderBanner5One.png"),
          text: "AI智能识别",
        },
        {
          url: require("../../assets/yingjian/ladderBanner5Two.png"),
          text: "语音提醒",
        },
        {
          url: require("../../assets/yingjian/ladderBanner5Three.png"),
          text: "远程报警",
        },
        {
          url: require("../../assets/yingjian/ladderBanner5Four.png"),
          text: "联动梯控",
        },
        {
          url: require("../../assets/yingjian/ladderBanner5Five.png"),
          text: "声光警告",
        },
        {
          url: require("../../assets/yingjian/ladderBanner5Six.png"),
          text: "时候追溯",
        },
      ],
      banner6List: [
        {
          url: require("../../assets/yingjian/ladderBanner6One.png"),
          text: "在监控放置区域接入智能监测电动车监控设备 并连接电梯控制器。",
        },
        {
          url: require("../../assets/yingjian/ladderBanner6Two.png"),
          text: "监控设备自带：智能检测，智能报警，语音对讲，红外照射等功能。",
        },
        {
          url: require("../../assets/yingjian/ladderBanner6Three.png"),
          text: "检测到是电动车，系统会发出声光警告，语音提醒。",
        },
        {
          url: require("../../assets/yingjian/ladderBanner6Four.png"),
          text: "联动梯控，电梯暂停运行，强制保持开门，电动车退出即可恢复正常。 ",
        },
        {
          url: require("../../assets/yingjian/ladderBanner6Five.png"),
          text: "检测到电动车后，自动呼叫值班室管理主机，并弹出相应电梯的视频画面",
        },
      ],
    };
  },

  mounted() { },

  methods: {
    maskShow(e) {
      this.active = e;
    },
    maskMove() {
      this.active = undefined;
    },
  },
};
</script>

<style lang="less" scoped>
.ladder {
  width: 100%;
}

.banner {
  width: 100%;
  height: 1080px;
  background-size: cover;
  background-repeat: no-repeat;

}

.banner1 {
  background-image: url("../../assets/yingjian/tkBanner.png");
}

.banner2 {
  box-sizing: border-box;
  background-image: url("../../assets/yingjian/ladderBnner2Bg.png");
  display: flex;
  justify-content: center;

  .ladderBanner2Content {
    width: 1600px;
    height: 720px;
    background-image: url("../../assets/yingjian/ladderBnnerCenterContent.png");
    background-size: cover;
    margin-top: 94px;
    position: relative;

    .ladderBanner2center {
      position: absolute;
      top: 568px;
      left: 145px;
      width: 1310px;
      height: 308px;
      background-image: linear-gradient(to top right,
          #e4f1ff,
          #fbfafa,
          #e9f3ff);
      border-radius: 20px;
      border: 1px solid #1367cc;
      padding: 61px 53px;
      text-align: left;
      box-sizing: border-box;
    }
  }

  .ladderBanner4Content {
    background-image: url("../../assets/yingjian/ladderBanner4Center.png") !important;
  }
}

.banner2Title {
  line-height: 1;
  font-size: 32px;
  color: #222;
  font-weight: bold;
}

.banner2Content {
  font-size: 18px;
  color: #333;
  line-height: 1.8;
  margin-top: 40px;
  text-align: justify;
  text-align-last: left;
}

.banner3 {
  background-image: url("../../assets/yingjian/ladderBanner3Bg.png");
  display: flex;
  align-items: center;
  justify-content: center;

  .banner3Content {
    width: 1600px;
    height: 700px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    .banner3ContentItem {
      box-sizing: border-box;
      width: 780px;
      height: 270px;
      background-size: cover;
      background-repeat: no-repeat;
      padding: 0 30px;
      text-align: left;
      padding-top: 172px;
      position: relative;
      z-index: 1;
    }
  }
}

.banner3ItemTitle {
  box-sizing: border-box;
  font-size: 32px;
  color: #fff;
  line-height: 1;
  position: absolute;
  top: 170px;
  z-index: 3;
}

.banner3ItemContent {
  box-sizing: border-box;
  font-size: 18px;
  color: #fff;
  margin-top: 20px;
  position: absolute;
  top: 200px;
  z-index: 3;
}

.banner3Mask {
  position: absolute;
  width: 780px;
  height: 270px;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  border-radius: 20px;
  z-index: 2;
}

.banner5 {
  background-image: url("../../assets/yingjian/ladderBanner5Bg.png");
  display: flex;
  align-items: center;
  justify-content: center;

  .banner5Center {
    width: 1600px;
    height: 750px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;

    .banner5Item {
      width: 500px;
      height: 330px;
      border-radius: 20px;
      border: 1px solid #1c7dff;
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: #fff;
    }
  }
}

.banner5Item {
  img {
    width: 195px;
    height: 170px;
    margin-top: 40px;
  }

  .banner5Text {
    font-size: 32px;
    color: #333;
    font-weight: 400;
    margin-top: 50px;
  }
}

.banner6 {
  background-image: url("../../assets/yingjian/ladderBanner6Bg.png");
  display: flex;
  align-items: center;
  justify-content: center;

  .banner6Center {
    width: 1600px;
    height: 850px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .banner6Item {
      width: 512px;
      height: 410px;
      background-size: cover;
      position: relative;
    }
  }
}

.banner6ItemFooter {
  position: absolute;
  bottom: 1px;
  left: 1px;
  width: 510px;
  height: 78px;
  background-color: #d1d1d1;
  border-bottom-left-radius: 11px;
  border-bottom-right-radius: 11px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 0 30px 0 20px;
  box-sizing: border-box;

  .Num {
    font-size: 32px;
    color: #333;
    font-weight: bold;
    margin-right: 20px;
  }

  .Text {
    font-size: 20px;
    color: #333;
    text-align: justify;
    text-align-last: left;
    line-height: 1.5;
  }
}
</style>
