<!--
 * @Author: flf flf@youlove.com.cn
 * @Date: 2023-01-03 16:26:32
 * @LastEditors: flf flf@youlove.com.cn
 * @LastEditTime: 2023-01-30 17:05:01
 * @FilePath: \ulove-web\src\components\footer.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <div class="company-footer">
        <div class="footer-top">
            <div class="left">
                <div class="logo">
                    <img alt="logo" src="../assets/ylflogo.png" />
                </div>
                <div class="phone">
                    <div>公司座机（工作日：8:30-17:00）</div>
                    <div>0571-89906536</div>
                </div>
            </div>
            <div class="right">
                <ul>
                    <li>解决方案</li>
                    <li @click="goUrl('/zhyj')">应急指挥平台</li>
                    <li @click="goUrl('/Security/Intelligence')">公安情报平台</li>
                    <li @click="goUrl('/Security/Platform')">公安合成作战指挥平台</li>
                    <li @click="goUrl('/Police/Head')">警务通/在线责任区</li>
                    <li @click="goUrl('/community')">智慧社区</li>
                    <li @click="goUrl('/park')">智慧园区</li>
                    <!-- <li>智慧社区</li>
                    <li>智慧园区</li> -->
                </ul>
                <ul>
                    <li>智能硬件</li>
                    <li @click="goUrl('/Yj/Charge')">智能充电桩</li>
                    <li @click="goUrl('/Yj/Ladder')">智能梯控/阻车</li>
                    <li @click="goUrl('/Yj/Access')">智能门禁</li>
                    <li @click="goUrl('/Yj/Switching')">智能车辆道闸</li>
                </ul>
                <ul>
                    <li @click="goUrl('/hyy')">好用云</li>
                </ul>
                <ul>
                    <li>关于我们</li>
                    <li @click="goUrl('/about?target=top')">公司简介</li>
                    <li @click="goUrl('/about?target=sy')">使命与愿景</li>
                    <li @click="goUrl('/about?target=ry')">荣誉资质</li>
                    <li @click="goUrl('/about?target=hz')">联系我们</li>
                    <li @click="goUrl('/about?target=hz')">业务合作</li>
                </ul>
                <ul>
                    <li>关注我们</li>
                    <li class="img">
                        <img src="../assets/gzh.png" alt="">
                        <div>好用点点公众号</div>
                    </li>
                    <li class="img">
                        <img src="../assets/xcx.png" alt="">
                        <div>好用点点小程序</div>
                    </li>
                </ul>
            </div>
        </div>
        <div class="footer-bottom">地址：杭州市余杭区金昌路2800号洋帆中心一号楼9楼 <span>Copyright©2012 - 2021杭州尤拉夫科技有限公司版权所有 </span> <a
                href="#">浙ICP备12012139号</a></div>
    </div>
</template>
<script>
export default {
    methods: {
        // 跳转页面
        goUrl(url) {
            if (url.indexOf(this.$route.path) > -1 && this.$route.path != "/") {
                let id = "#" + url.split("=")[1];
                document.querySelector(id).scrollIntoView({
                    behavior: "smooth",
                });
            } else {
                window.scrollTo(0, 0);
                this.$router.push({
                    path: url,
                });
            }
        }
    }
}
</script>
<style lang="less" scoped>
.company-footer {
    width: 100%;
    background: url(../assets/footer.png);
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    text-align: left;
}

.footer-top {
    display: flex;
    width: 1560px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    padding-top: 94px;
    border-bottom: 1px solid #3F404C;
    padding-bottom: 20px;

    .left {
        .logo img {
            width: 268px;
            height: 50px;
        }
    }

    .phone {
        margin-top: 95px;
        font-size: 40px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 40px;

        div:first-child {
            font-size: 14px;
            font-family: PingFang SC;
            line-height: 40px;
        }
    }

    .right {
        display: flex;
        flex: 1;
        margin-left: 338px;
        justify-content: space-between;

        ul{
        margin-top: 0px;
        }
        ul li {
            list-style: none;
            cursor: pointer;

        }

        ul li:first-child {
            font-size: 18px;
            font-family: PingFang SC;
            font-weight: bold;
            color: #FFFFFF;
            line-height: 26px;
        }
        li {
            font-size: 14px;
            font-family: PingFang SC;
            color: #FFFFFF;
            line-height: 40px;
            text-align: center;
        }

        li img {
            width: 90px;
            height: 90px;
        }

        ul:last-child {
            margin-right: 0;
        }

        .img {
            margin-bottom: 20px;
        }

        .img div {
            line-height: 20px;
        }
    }
}

.footer-bottom {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 40px;
    text-align: center;
    padding-top: 40px;
    padding-bottom: 96px;

    span {
        margin-left: 40px;
    }

    a {
        color: #0567FB;
        text-decoration: none;
    }
}
</style>